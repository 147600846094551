import Vue from "vue";
import Router from "vue-router";
import store from "../store/";

// import Login from "@/pages/login";
// import Home from "@/pages/Home";
// import Questions from "@/pages/Questions";
// import QuestionSingle from "@/pages/QuestionSingle";
// import CoolInstalls from "@/pages/CoolInstalls";
// import Listings from "@/pages/Listings";
// import RFRs from "@/pages/RFRs";
// import RGAs from "@/pages/RGAs";
// import Images from "@/pages/Images";
// import Videos from "@/pages/Videos";
// import TrainingVideos from "@/pages/TrainingVideos";
// import PDFs from "@/pages/PDFs";

/*
import Presentations from "@/pages/Presentations";
import Viewer from "@/pages/Viewer";
import ProductMarketing from "@/pages/ProductMarketing";
import ProductMarketingSingle from "@/pages/ProductMarketingSingle";
import ChallengerInsights from "@/pages/ChallengerInsights";
import ChallengerInsightsSingle from "@/pages/ChallengerinsightsSingle";
import ACList from "@/pages/ACLookup";
import UserDirectory from "@/pages/UserDirectory";
import NotFound from "@/pages/404.vue";
*/
//import VueAnalytics from "vue-analytics";

const Login = () => import("@/pages/login");
const Forgot = () => import("@/pages/Forgot");
const Reset = () => import("@/pages/Reset");
const Home = () => import("@/pages/Home");
const HomeNew = () => import("@/pages/HomeNew");
const HomeVids = () => import("@/pages/HomeVids");
const HomeProjects = () => import("@/pages/HomeProjects")
const Announcements = () => import("@/pages/Announcements");
const Questions = () => import("@/pages/Questions");
const QuestionSingle = () => import("@/pages/QuestionSingle");
const FAQ = () => import("@/pages/faq");
const ETOProjects = () => import("@/pages/ETOProjects");
const ETOProjectDetails = () => import("@/pages/ETOProjectDetails");
const Listings = () => import("@/pages/Listings");
const ListingsCalendar = () => import("@/pages/ListingsCalendar");
const Invoices = () => import("@/pages/Invoices");
const Acknowledgments = () => import("@/pages/Acknowledgments");
const ExpeditedOrders = () => import("@/pages/ExpeditedOrders");
const ExpeditedOrderDetails = () => import("@/pages/ExpeditedOrderDetails");
const ExpeditedOrderAdd = () => import("@/pages/ExpeditedOrderAdd");
const ExpeditedOrderTest = () => import("@/pages/ExpeditedOrdersTest");
const RFRs = () => import("@/pages/RFRs");
const RFRDetails = () => import("@/pages/RFRDetails");
const RFRadd = () => import("@/pages/RFRadd");
const RGAs = () => import("@/pages/RGAs");
const RGADetails = () => import("@/pages/RGADetails");
const RGAadd = () => import("@/pages/RGAadd");
const CommentAdd = () => import("@/pages/CommentAdd");
const Warranty = () => import("@/pages/warranty");
const GSAs = () => import("@/pages/GSAs");
const ADS = () => import("@/pages/ADS");
const Parts = () => import("@/pages/PriceLists");
const ReportsNew = () => import("@/pages/reportsNew");
const SalesReps = () => import("@/pages/salesReps");
const ReportDetails = () => import("@/pages/reportDetails");
const FavMedia = () => import("@/pages/FavMedia");
const Media = () => import("@/pages/Media2");
const FieldService = () => import("@/pages/FieldService");
const SalesTraining = () => import("@/pages/SalesTraining");
const TrainingEssentials = () => import("@/pages/TrainingEssentials")
const Images = () => import("@/pages/Images");
//const Videos = () => import("@/pages/Videos");
//const TrainingVideos = () => import("@/pages/TrainingVideos");
//const PDFs = () => import("@/pages/PDFs");
//const Presentations = () => import("@/pages/Presentations");
//const Viewer = () => import("@/pages/Viewer");
const FromFort = () => import("@/pages/FromFort");
const Trailblazor = () => import("@/pages/Trailblazor");
const TrailblazorSingle = () => import("@/pages/TrailblazorSingle");
const ChallengerInsights = () => import("@/pages/ChallengerInsights");
const ChallengerInsightsSingle = () => import("@/pages/ChallengerinsightsSingle");
const AnnouncementSingle = () => import("@/pages/AnnouncementSingle");
const ACList = () => import("@/pages/ACLookup");
const UserDirectory = () => import("@/pages/UserDirectory");
const OrderDetails = () => import("@/pages/OrderDetails");
const LeadTimes = () => import("@/pages/LeadTimes");
const LeadTimesEdit = () => import("@/pages/LeadTimesEdit");
const LeadTimeHistory = () => import("@/pages/LeadTimeHistory");
const CashManagement = () => import("@/pages/CashManagement");
const CashManagementSingle = () => import("@/pages/CashManagementSingle");
const Interterritorial = () => import("@/pages/Interterritorial");
const InterterritorialAdd = () => import("@/pages/InterterritorialAdd");
const InterterritorialDetails = () => import("@/pages/InterterritorialDetails");
const Calendar = () => import("@/pages/Calendar");
const ReleaseNotes = () => import("@/pages/ReleaseNotes");
const MajorProjects = () => import("@/pages/MajorProjectsRegistration");
const Tradeshow = () => import("@/pages/Tradeshow");
const Admin = () => import("@/pages/Admin");
// const Todo = () => import("@/pages/todo");
const LockerConfig = () => import("@/pages/lockerConfig");
//const PieChart = () => import("@/pages/PieChart");
// import Ideas from '@/pages/Ideas'
// import IdeaSingle from '@/pages/IdeaSingle'
const NotFound = () => import("@/pages/404.vue");
import * as Sentry from "@sentry/vue";


Vue.use(Router);

let router = new Router({
	mode: "history",
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			// savedPosition is only available for popstate navigations.
			return savedPosition
		} else {
			const position = {}

			/*
			return new Promise(resolve => {
				// check if any matched route config has meta that requires scrolling to top
				if (to.matched.some(m => m.meta.scrollToTop)) {
					// coords will be used if no selector is provided,
					// or if the selector didn't match any element.
					position.x = 0
					position.y = 0
				}

				// wait for the out transition to complete (if necessary)
				this.app.$root.$once('triggerScroll', () => {
					// if the resolved position is falsy or an empty object,
					// will retain current scroll position.
					resolve(position)
				})
			})
			*/

			if (to.matched.some(m => m.meta.scrollToTop)) {
				// coords will be used if no selector is provided,
				// or if the selector didn't match any element.
				position.x = 0
				position.y = 0
				document.getElementById('mobileScrollTarget').scrollIntoView();
			} else {
				return { x: 0, y: 0 };
			}

		}
		/*
				if (
					to.name === 'OrderDetails' ||
					to.name === 'ExpeditedOrderDetails' ||
					to.name === 'RFRDetails' ||
					to.name === 'RGADetails' ||
					to.name === 'ReportDetails' ||
					to.name === 'InterterritorialDetails'
				) {
					document.getElementById('mobileScrollTarget').scrollIntoView();
				} else {
					return { x: 0, y: 0 };
				}
				*/
	},
	routes: [
		{
			path: "/",
			name: "Login",
			component: Login,
		},
		{
			path: "/forgot",
			name: "Forgot",
			component: Forgot,
		},
		{
			path: "/reset",
			name: "Reset",
			component: Reset,
		},
		{
			path: "/home",
			name: "Home",
			component: store.state.isDashboard ? HomeProjects : Home,
			meta: {
				requiresAuth: true,
			},
		},
		
		/*
		{
			path: "/home2",
			name: "HomeProjects",
			component: store.state.isDashboard ? HomeProjects : Home,
			meta: {
				requiresAuth: true,
			},
		},
		*/
	
		{
			path: "/announcements",
			name: "Announcements",
			component: Announcements,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/questions/:id",
			name: "QuestionSingle",
			component: QuestionSingle,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/questions",
			name: "Questions",
			component: Questions,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/faq",
			name: "FAQ",
			component: FAQ,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/eto-and-inspiring-installs",
			name: "ETOProjects",
			component: ETOProjects,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/eto-and-inspiring-installs/:id",
			name: "ETOProjectDetails",
			component: ETOProjectDetails,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/listings",
			name: "Listings",
			component: Listings,
			meta: {
				requiresAuth: true,
				requiresNotExternalPerson: true
			},
		},
		{
			path: "/listings-cal",
			name: "ListingsCalendar",
			component: ListingsCalendar,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/invoices",
			name: "Invoices",
			component: Invoices,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/acknowledgments",
			name: "Acknowledgments",
			component: Acknowledgments,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/expedited-orders",
			name: "ExpeditedOrders",
			component: ExpeditedOrders,
			meta: {
				requiresAuth: true,
				requiresNotExternalPerson: true
			},
		},
		{
			path: "/expedited-order-details/:id",
			name: "ExpeditedOrderDetails",
			component: ExpeditedOrderDetails,
			meta: {
				requiresAuth: true,
				scrollToTop: true,
				requiresNotExternalPerson: true
			},
		},
		{
			path: "/expedited-order/add/:id",
			name: "ExpeditedOrderAdd",
			component: ExpeditedOrderAdd,
			meta: {
				requiresAuth: true,
				requiresNotExternalPerson: true,
			},
		},
		{
			path: "/expedited-order/test/",
			name: "ExpeditedOrderTest",
			component: ExpeditedOrderTest,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/rfrs",
			name: "RFRs",
			component: RFRs,
			meta: {
				requiresAuth: true,
				requiresNotExternalPerson: true
			},
		},
		{
			path: "/rfr-details/:id",
			name: "RFRDetails",
			component: RFRDetails,
			meta: {
				requiresAuth: true,
				scrollToTop: true,
				requiresNotExternalPerson: true
			},
		},
		{
			path: "/rfr/add/:id",
			name: "RFRadd",
			component: RFRadd,
			meta: {
				requiresAuth: true,
				requiresNotExternalPerson: true
			},
		},
		{
			path: "/rgas",
			name: "RGAs",
			component: RGAs,
			meta: {
				requiresAuth: true,
				requiresNotExternalPerson: true
			},
		},
		{
			path: "/rga-details/:id",
			name: "RGADetails",
			component: RGADetails,
			meta: {
				requiresAuth: true,
				scrollToTop: true,
				requiresNotExternalPerson: true
			},
		},
		{
			path: "/rga/add/:id",
			name: "RGAadd",
			component: RGAadd,
			meta: {
				requiresAuth: true,
				requiresNotExternalPerson: true
			},
		},
		{
			path: "/comment/add/:id",
			name: "CommentAdd",
			component: CommentAdd,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/warranty",
			name: "Warranty",
			component: Warranty,
			meta: {
				requiresAuth: true,
				requiresNotExternalPerson: true
			},
		},
		{
			path: "/gsas",
			name: "GSAs",
			component: GSAs,
			meta: {
				requiresAuth: true,
				requiresNotExternalPerson: true
			},
		},
		{
			path: "/ads",
			name: "ADS",
			component: ADS,
			meta: {
				requiresAuth: true,
				requiresNotExternalPerson: true
			},
		},
		{
			path: "/price-lists",
			name: "Parts",
			component: Parts,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/reports",
			name: "ReportsNew",
			component: ReportsNew,
			meta: {
				requiresAuth: true,
				requiresReports: true,
				requiresNotExternalPerson: true
			},
		},
		{
			path: "/reports/sales-reps",
			name: "SalesReps",
			component: SalesReps,
			meta: {
				requiresAuth: true,
				requiresReports: true,
				requiresNotSales: true,
				requiresNotExternalPerson: true
			},
		},
		{
			path: "/reportdetails/",
			name: "ReportDetails",
			component: ReportDetails,
			meta: {
				requiresAuth: true,
				requiresReports: true,
				scrollToTop: true,
				requiresNotExternalPerson: true
			},
		},
		{
			path: "/media-favorites",
			name: "FavMedia",
			component: FavMedia,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/marketing-materials",
			name: "Media",
			component: Media,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/service-and-installation",
			name: "FieldService",
			component: FieldService,
			meta: {
				requiresAuth: true,
			},
		},
		/*
		{
			path: "/marketing-materials/:id",
			name: "Media",
			component: Media,
			meta: {
				requiresAuth: true,
			},
		},
		*/
		{
			path: "/images",
			name: "Images",
			component: Images,
			meta: {
				requiresAuth: true,
			},
		},
		/*
		{
			path: "/videos",
			name: "Videos",
			component: Videos,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/documents",
			name: "PDFs",
			component: PDFs,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/presentations",
			name: "Presentations",
			component: Presentations,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/view/:id",
			name: "Viewer",
			component: Viewer,
			meta: {
				requiresAuth: true,
			},
		},
		*/
		{
			path: "/from-the-fort",
			name: "FromFort",
			component: FromFort,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/trailblazer",
			name: "trailblazor",
			component: Trailblazor,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/trailblazor/:slug",
			name: "trailblazorPost",
			component: TrailblazorSingle,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/announcement/:slug",
			name: "AnnouncementSingle",
			component: AnnouncementSingle,
			meta: {
				requiresAuth: true,
			},
		},

		{
			path: "/challenger-insights",
			name: "challengerInsights",
			component: ChallengerInsights,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/challenger-insights/:slug",
			name: "challengerinsightsPost",
			component: ChallengerInsightsSingle,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/ac-list",
			name: "ACList",
			component: ACList,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/user-directory",
			name: "UserDirectory",
			component: UserDirectory,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/sales-and-training",
			name: "SalesTraining",
			component: SalesTraining,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/sales-and-training/training-essentials",
			name: "TrainingEssentials",
			component: TrainingEssentials,
			meta: {
				requiresAuth: true,
			},
		},

		
		/*
		{
			path: "/training-videos",
			name: "TrainingVideos",
			component: TrainingVideos,
			meta: {
				requiresAuth: true,
			},
		},
		*/
		{
			path: "/order-details/:id",
			name: "OrderDetails",
			component: OrderDetails,
			meta: {
				requiresAuth: true,
				scrollToTop: true,
				requiresNotExternalPerson: true
			},
		},
		{
			path: "/lead-times",
			name: "LeadTimes",
			component: LeadTimes,
			meta: {
				requiresAuth: true,
				requiresNotExternalPerson: true
			},
		},
		{
			path: "/lead-times/edit",
			name: "LeadTimesEdit",
			component: LeadTimesEdit,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/lead-time-history",
			name: "LeadTimeHistory",
			component: LeadTimeHistory,
			meta: {
				requiresAuth: true,
				requiresSpacesaverEmployee: true,
			},
		},
		{
			path: "/cash-management",
			name: "CashManagement",
			component: CashManagement,
			meta: {
				requiresAuth: true,
				requiresCashManagement: true,
				requiresNotExternalPerson: true
			},
		},
		{
			path: "/cash-management/:id",
			name: "CashManagementSingle",
			component: CashManagementSingle,
			meta: {
				requiresAuth: true,
				requiresCashManagement: true,
				requiresNotExternalPerson: true
			},
		},
		{
			path: "/interterritorial",
			name: "Interterritorial",
			component: Interterritorial,
			meta: {
				requiresAuth: true,
				requiresNotExternalPerson: true
			},
		},
		{
			path: "/interterritorial/add",
			name: "InterterritorialAdd",
			component: InterterritorialAdd,
			meta: {
				requiresAuth: true,
				requiresNotExternalPerson: true
			},
		},
		{
			path: "/interterritorial/:id",
			name: "InterterritorialDetails",
			component: InterterritorialDetails,
			meta: {
				requiresAuth: true,
				scrollToTop: true,
				requiresNotExternalPerson: true
			},
		},
		{
			path: "/calendar",
			name: "Calendar",
			component: Calendar,
			meta: {
				requiresAuth: true,
			},
		},

			{
				path: "/configurator",
				name: "LockerConfig",
				component: LockerConfig,
				meta: {
					requiresAuth: true,
				},
			},
			

		{
			path: "/release-notes",
			name: "ReleaseNotes",
			component: ReleaseNotes,
			meta: {
				requiresAuth: true,
			},
		},
		
		{
			path: "/major-project-registration",
			name: "MajorProjects",
			component: MajorProjects,
			meta: {
				requiresAuth: true,
			},
		},
		{
			path: "/tradeshow-requests",
			name: "Tradeshow",
			component: Tradeshow,
			meta: {
				requiresAuth: true,
			},
		},
		

		{
			path: "/admin",
			name: "Admin",
			component: Admin,
			meta: {
				requiresAuth: true,
				requiresAdmin: true,
			},
		},
		{ path: "*", component: NotFound },
	],
	/*
	scrollBehavior(to, from, savedPosition) {
		return { x: 0, y: 0 };
	},
	*/
});


router.beforeEach((to, from, next) => {
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		if (store.getters.isLoggedIn) {
			Sentry.setUser({ email: store.state.user.Email });
			next();
			return;
		}

		//next("/");
		//const loginpath = window.location.pathname;
		const loginpath = window.location.url;
		//next({ name: "Login", query: { from: loginpath } });
		next({ name: "Login", query: { from: to.fullPath } });
		next();
		//this.$router.push({ name: "Login", query: { redirect: "/path" } });
	} else {
		next();
	}
});


router.beforeEach((to, from, next) => {
	if (to.matched.some((record) => record.meta.requiresAuth)) {

		if (store.getters.isLoggedIn) {
			//console.log("is logged in in store");
			next();
			return;
		} else {
			//console.log("isn't logged in in store");
			next({
				path: '/',
				query: { redirect: to.fullPath }
			})
		}
		//next("/");

	} else {
		next();
	}
});


router.beforeEach((to, from, next) => {
	if (to.matched.some((record) => record.meta.requiresAdmin)) {
		if (store.getters.isAdmin) {
			next();
			return;
		}

		next("/");
	} else {
		next();
	}
});

router.beforeEach((to, from, next) => {
	if (to.matched.some((record) => record.meta.requiresSpacesaverEmployee)) {
		if (store.getters.isSpacesaverEmployee) {
			next();
			return;
		}

		next("/");
	} else {
		next();
	}
});

router.beforeEach((to, from, next) => {
	if (to.matched.some((record) => record.meta.requiresCashManagement)) {
		if (store.getters.isCashManagement) {
			next();
			return;
		}

		next("/");
	} else {
		next();
	}
});

router.beforeEach((to, from, next) => {
	if (to.matched.some((record) => record.meta.requiresReports)) {
		if (store.getters.isReports) {
			next();
			return;
		}

		next("/");
	} else {
		next();
	}
});

router.beforeEach((to, from, next) => {
	if (to.matched.some((record) => record.meta.requiresNotSales)) {
		if (!store.getters.isSales) {
			next();
			return;
		}

		next("/");
	} else {
		next();
	}
});


router.beforeEach((to, from, next) => {
	if (to.matched.some((record) => record.meta.requiresNotExternalPerson)) {
		if (!store.getters.isExternalPerson) {
			next();
			return;
		}

		next("/");
	} else {
		next();
	}
});

/*
navigation duplicated exception fix. This exception gets thrown when an individual is on a page. 
and tries to navigate to the same page
*/


const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => {
		//console.log(err.name);
		if (err.name !== 'NavigationDuplicated') throw err
	});
}


/*
const originalPush = router.push
router.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject) {
		return originalPush.call(this, location, onResolve, onReject)
	}

	return originalPush.call(this, location).catch((err) => {
		if (router.isNavigationFailure(err)) {
			return err
		}

		return Promise.reject(err)
	})
}
*/



//import 'vuejs-noty/dist/vuejs-noty.css'
/*
Vue.use(VueAnalytics, {
	id: "UA-151489011-1",
	router,
});
*/

export default router;
